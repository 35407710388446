* {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  font-family: "Sofia Pro" !important;
}
:root,
body,
html {
  scroll-behavior: smooth;
}
div,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
img,
label,
li,
nav,
p,
small,
span,
ul {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  /* font-size: 100%; */
  vertical-align: baseline;
  background: transparent;
}

@keyframes animation1 {
  0% {
    width: 0;
    height: 0;
    transform: translateY(0);
    top: 0;
  }
  10% {
    top: 0;
    transform: translateY(0);
    width: 506.37px;
    height: 300.4px;
  }
  25% {
    transform: translate(0, 0);
  }
  30% {
    transform: translate(0, 91%);
  }
  35% {
    transform: translate(0, 91%);
  }
  40% {
    transform: translate(0%, 91%) rotate(90deg);
  }
  45% {
    transform: translate(0%, 91%) rotate(90deg);
  }
  50% {
    transform: translate(73%, 50%) rotate(90deg);
  }
  55% {
    transform: translate(73%, 50%) rotate(90deg);
  }
  65% {
    transform: translate(73%, 50%) rotate(0deg);
  }
  70% {
    transform: translate(0%) rotate(0deg);
  }
  100% {
    transform: translate(0%) rotate(0deg);
  }
}
@keyframes animation12 {
  0% {
    width: 0;
    height: 0;
    transform: translateY(0);
    top: 0;
  }
  10% {
    top: 0;
    transform: translateY(0);
    width: 341.42px;
    height: 190.58px;
  }
  25% {
    transform: translate(0, 0);
  }
  30% {
    transform: translate(0, 91%);
  }
  35% {
    transform: translate(0, 91%);
  }
  40% {
    transform: translate(0%, 91%) rotate(90deg);
  }
  45% {
    transform: translate(0%, 91%) rotate(90deg);
  }
  50% {
    transform: translate(73%, 50%) rotate(90deg);
  }
  55% {
    transform: translate(73%, 50%) rotate(90deg);
  }
  65% {
    transform: translate(73%, 50%) rotate(0deg);
  }
  70% {
    transform: translate(0%) rotate(0deg);
  }
  100% {
    transform: translate(0%) rotate(0deg);
  }
}
@keyframes animation123 {
  0% {
    width: 0;
    height: 0;
    transform: translateY(0);
    top: 0;
  }
  10% {
    top: 0;
    transform: translateY(0);
    width: 170.42px;
    height: 110.58px;
  }
  25% {
    transform: translate(0, 0);
  }
  30% {
    transform: translate(0, 91%);
  }
  35% {
    transform: translate(0, 91%);
  }
  40% {
    transform: translate(0%, 91%) rotate(90deg);
  }
  45% {
    transform: translate(0%, 91%) rotate(90deg);
  }
  50% {
    transform: translate(73%, 50%) rotate(90deg);
  }
  55% {
    transform: translate(73%, 50%) rotate(90deg);
  }
  65% {
    transform: translate(73%, 50%) rotate(0deg);
  }
  70% {
    transform: translate(0%) rotate(0deg);
  }
  100% {
    transform: translate(0%) rotate(0deg);
  }
}

@keyframes animation2 {
  0% {
    transform: translate(0, -20px);
    opacity: 0;
  }
  14% {
    transform: translate(0, -20px);
    opacity: 0;
  }
  18% {
    transform: translate(0, 0);
    opacity: 1;
  }
  20% {
    transform: translate(88%, -63%);
  }
  30% {
    transform: translate(88%, -63%);
  }
  35% {
    transform: translate(0%, -94%);
  }
  60% {
    transform: translate(0%, -94%);
  }
  70% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
@keyframes animation3 {
  0% {
    width: 0;
    height: 0;
    transform: translate(0, 0);
  }
  13% {
    transform: translate(0, 0);
    width: 243.06px;
    height: 293.1px;
  }
  25% {
    transform: translate(-100%, 78%);
  }
  28% {
    transform: translate(-100%, 78%) scale(1.5);
  }
  30% {
    transform: translate(-100%, -50%) scale(1.5);
  }
  45% {
    transform: translate(-100%, -50%) scale(1);
  }
  50% {
    transform: translate(-100%, 78%) scale(1);
  }
  70% {
    transform: translate(-100%, 78%) scale(1.5);
  }
  80% {
    transform: translate(0%, 0%) scale(1);
  }
  100% {
    transform: translate(0%, 0%) scale(1);
  }
}
@keyframes animation32 {
  0% {
    width: 0;
    height: 0;
    transform: translate(0, 0);
  }
  13% {
    transform: translate(0, 0);
    width: 184.26px;
    height: 222.19px;
  }
  25% {
    transform: translate(-100%, 78%);
  }
  28% {
    transform: translate(-100%, 78%) scale(1.5);
  }
  30% {
    transform: translate(-100%, -50%) scale(1.5);
  }
  45% {
    transform: translate(-100%, -50%) scale(1);
  }
  50% {
    transform: translate(-100%, 78%) scale(1);
  }
  70% {
    transform: translate(-100%, 78%) scale(1.5);
  }
  80% {
    transform: translate(0%, 0%) scale(1);
  }
  100% {
    transform: translate(0%, 0%) scale(1);
  }
}
@keyframes animation323 {
  0% {
    width: 0;
    height: 0;
    transform: translate(0, 0);
  }
  13% {
    transform: translate(0, 0);
    width: 128.26px;
    height: 106.19px;
  }
  25% {
    transform: translate(-100%, 50%);
  }
  28% {
    transform: translate(-100%, 50%) scale(1.5);
  }
  30% {
    transform: translate(-100%, -50%) scale(1.5);
  }
  45% {
    transform: translate(-100%, -50%) scale(1);
  }
  50% {
    transform: translate(-100%, 50%) scale(1);
  }
  70% {
    transform: translate(-100%, 50%) scale(1.5);
  }
  80% {
    transform: translate(0%, 0%) scale(1);
  }
  100% {
    transform: translate(0%, 0%) scale(1);
  }
}

@keyframes navbarAnimation {
  0%{
    top: -100px;
  }
  100%{
    top: 0;
  }
}
