

$main-color: #09216A;
$light-color: #FFFFFF;
$text-color: #000F3C;


// button background

$button-bg1: #2d4798;
$button-bg2: #FF2853;


