.box_container {
  transition: 0.2s;
  color: #d0dbfb;
  flex-shrink: 0;

  width: 219px;
  height: 209px;
  border-radius: 15px;
  font-size: 18px;

  @media (max-width: 768px) {
    width: 95px;
    height: 91px;
    font-size: 12px;
    border-radius: 10px;
  }

  span {
    font-weight: 500;
  }
  svg,
  img {
    width: 25px;
    margin-bottom: 11px;
  }
  .tool_box_container {
    background-color: transparent;
    visibility: hidden;
    position: absolute;
    bottom: 0;

    svg,
    img {
      transform: scale(0.9);
      transition: 0.2s;
      width: 35px;
      height: 35px;

      &:hover {
        transform: scale(1);
      }
    }

    & > :first-child {
    }
  }

  &:hover {
    .tool_box_container {
      visibility: visible;
      cursor: pointer;
    }
  }
}
