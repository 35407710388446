.flex-basic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.input-basic {
  background: #f8faff;
  border: 1px solid #000000;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 17px;
  color: #000f3c;
  margin-right: 15px;
  height: 100%;
  width: 75%;
  padding: 0 19px;

  &:focus {
    outline: none;
  }
}

.card-basic {
  background: $light-color;
  box-shadow: 0px 0px 20px 10px #17384205;
  border-radius: 3px;
  padding: 28px 34px;
  @extend .flex-basic;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 30px 10px rgba(87, 128, 255, 0.35);
  }
  img {
    // width: 43px;
    // height: 27.69px;
        object-fit: contain;
        height: 35px;
    margin-top: 20px;
  }
  h4 {
    font-weight: 500;
    font-size: 20px;
    color: #000f3c;
    margin: 26px 26px;
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    color: #000f3c;
    width: 100%;
    text-align: start;
    margin-bottom: 6px;
    text-transform: capitalize;
    span {
      font-weight: bold;
      font-size: 15px;
      color: #000f3c;
      // margin-left: 20px;
    }
  }
  p.count {
    background: #ff2853;
    box-shadow: 0px 4px 10px 10px rgba(87, 128, 255, 0.1);
    border-radius: 18px;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
    width: auto;
    display: inline-block;
    padding: 6px 18px;
    span {
      font-weight: inherit;
      font-size: inherit;
      color: inherit;
      margin-left: 0;
    }
  }
  @media (max-width: 1400px) and (min-width: 1024px) {
    p {
      font-size: 12px !important;
      span {
        font-size: 12px !important;
      }
    }
  }
}

.progress {
  width: 100%;
  height: 8px;
  background: $light-color;
  border-radius: 5px;
  margin-top: 10px;
  position: relative;
  .progass-complate {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: $button-bg2;
    border-radius: 5px;
  }
}
